
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import EyeIcon from '@/assets/icons/eye-outline.svg';
import router from '@/router';
import Button from '@/shared/components/Button.vue';
import Card from '@/shared/components/Card.vue';
import TextInput from '@/shared/components/form/TextInput.vue';

export default {
    components: {
        Card,
        TextInput,
        Button,
        EyeIcon,
    },
    setup() {
        const store = useStore();
        const error = ref('');
        const route = useRoute();
        const token = ref(route.query.token);
        const email = ref({
            value: '',
            label: 'E-mailadres',
            type: 'email',
            error: 'E-mailadres is ongeldig.',
            hasErrors: false,
        });

        const password = ref({
            value: '',
            label: 'Wachtwoord',
            type: 'password',
            hasErrors: false,
        });

        function validateEmail() {
            const re = /\S+@\S+\.\S+/;
            email.value.hasErrors = !re.test(email.value.value);
        }

        function showPassword() {
            if (password.value.type === 'password') {
                password.value.type = 'text';
            } else {
                password.value.type = 'password';
            }
        }

        if (token.value) {
            const formData = {
                token: token.value,
            };

            store.dispatch('LOGIN_WITH_TOKEN', formData).then(() => {
                store.dispatch('CART_COUNT');
                router.push({ name: 'Favorites' });
            }).catch((err: any) => {
                if (err.response.data.data.type === 'error') {
                    error.value = err.response.data.data.attributes.message;
                }
            });
        }

        function login() {
            const emailVal = email.value;
            const passVal = password.value;

            const formData = {
                email: emailVal.value,
                password: passVal.value,
            };

            validateEmail();

            if (emailVal.hasErrors || passVal.hasErrors) {
                return;
            }

            store.dispatch('LOGIN', formData).then(() => {
                store.dispatch('CART_COUNT');
                router.push({ name: 'Favorites' });
            }).catch((err: any) => {
                if (err.response.data.data.type === 'error') {
                    error.value = err.response.data.data.attributes.message;
                }
            });
        }

        return {
            email,
            password,
            showPassword,
            validateEmail,
            login,
            error,
            store,
        };
    },
};
